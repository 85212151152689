import SectionHeading from '../../../components/SectionHeading'
import TokenInfo2 from './TokenInfo2'

const SecDistribution = ({ img, data }) => {

    return (
        <section className="token-distribution">
            <div className="container">

                <SectionHeading
                    title='Tokenomics'
                    text=''
                />
                <div className="col-lg-6 col-md-6 col-sm-12 mt-1">
                    <div className="token-allocation">
                        <img src={img} className="center-block" alt="" />
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="token-info-wapper"></div>
                    {data && data.map((item, key) => (
                        <TokenInfo2
                            key={key}
                            text={item.text}
                        />
                    ))}
                </div>
            </div>

        </section>
    );
}

export default SecDistribution;