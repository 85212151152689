const TokenInfo2 = ({text , img}) => {

  return (
    <div className="token-info">
        <div className="text-white">
            <div className="token-descr">{text}</div>
        </div>
    </div>
  );
}

export default TokenInfo2;