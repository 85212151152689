import { useEffect } from "react";
import './style/HomeDemo1.scss'

import {
  VerticalSocial,
  SingleCoolFact,
  service_single_content,
  timelineInfo,
  ServiceBlock,
  SocialListIco,
  FQAInfo,
  DocElementTitle,
  TokenText,
  TeamMember,
  PartnersData
} from '../../data/data-containers/data-HomeDemo1.js';

import {
  HomeDemo1About1,
  HomeDemo1Solution,
  HomeDemo1VideoBg4,
  HomeDemo1ImgPhone,
  HomeDemo1RingsBg,
  HomeDemo1Allocation,
  HomeDemo3BgRoadmap
} from '../../utils/allImgs'

import { handelTitle } from '../../utils'
import Header from "../../layouts/Header"
import Footer from '../../layouts/FooterPages'

import SecWelcomeArea from './SecWelcomeArea'
import SecVerticalSocial from './SecVerticalSocial'
import SecTrust from './SecTrust'
import SecAboutUsClient from './SecAboutUsClient'
import SecAboutUs from './SecAboutUs'
import SecDemoVideo from './SecDemoVideo'
import SecOurServices from './SecOurServices'
import SecOurRoadmap from './SecOurRoadmap'
import SecOurFeatures from './SecOurFeatures'
import SecSubscribe from './SecSubscribe'
import SecFAQ_Timeline from './SecFAQ_Timeline'
import SecDistribution from './SecDistribution'
import SecTeam from './SecTeam'
import SecPartners from './SecPartners'

let HomeDemo1 = () => {

  useEffect(() => {
    handelTitle('Home Template1')
  }, [])

  useEffect(() => {
    if (document.title === 'Home Template1') {
      document.getElementsByTagName("body")[0].style.backgroundImage = 'linear-gradient(rgb(36, 0, 68) 0px, rgb(15, 2, 64) 25%, rgb(64, 9, 89) 40%, rgb(15, 2, 64) 65%, rgb(15, 2, 64))'
    } else {
      document.getElementsByTagName("body")[0].style.backgroundImage = 'linear-gradient(rgb(36, 0, 68) 0px, rgb(15, 2, 64) 25%, rgb(64, 9, 89) 40%, rgb(15, 2, 64) 65%, rgb(15, 2, 64))'
    }
  }, [])

  return (
    <>
      <Header />
      <div className="HomeDemo1">
        <SecWelcomeArea />
        <SecVerticalSocial data={VerticalSocial} />
        {/* <SecTrust data={SingleCoolFact} /> */}
        <SecAboutUsClient img={HomeDemo1About1} />
        <SecAboutUs img={HomeDemo1Solution} />
        <div className="clearfix" />
        {/* <SecDemoVideo img={HomeDemo1VideoBg4} />
        <div className="clearfix" /> */}
        <SecOurServices data={service_single_content} />
        <SecOurRoadmap data={timelineInfo} img={HomeDemo3BgRoadmap} />
        <SecOurFeatures data={ServiceBlock} imgPhone={HomeDemo1ImgPhone} Rings={HomeDemo1RingsBg} />
        <SecSubscribe data={SocialListIco} />
        <SecFAQ_Timeline FQAInfo={FQAInfo} DocElementTitle={DocElementTitle} />
        <SecDistribution img={HomeDemo1Allocation} data={TokenText} />
        {/* <SecTeam data={TeamMember} /> */}
        <SecPartners data={PartnersData} />
      </div>

      {/* <iframe
        style={{
          right: '100px',
          height:"500px",
          width: '500px'
        }}
        
        src="https://chat-window.mojo.vn/chat?token=f09e3a82-9238-4fe6-8e19-6bae50d070b8&inbox=af168a0f-c3ca-493d-ae07-31ab1378a284">
      </iframe> */}
      <Footer />
    </>
  );
};

export default HomeDemo1